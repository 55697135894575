import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { verify } from "specified";

import { campaign as campaignSpec, labels } from "../specs/salesforce";

const choices = [
  "FrozenII-2weeks",
  "FrozenII-4weeks",
  "FrozenII-6weeks",
] as const;
// const acceptedStatusses = [200, 201, 401, 432, 433, 434, 500] as const;

export const setCompensation = async (
  peopleId: string,
  signature: string,
  choice: typeof choices[number],
  campaignId: string
) => {
  if (!choices.includes(choice)) {
    return 500;
  }
  return doCallout({
    method: "POST",
    url: `${process.env.REACT_APP_FROZEN_II_ENDPOINT}/member/${peopleId}/postpone?signature=${signature}&campaignId=${campaignId}`,
    data: { choice },
  });
};

export const checkCompensation = (
  peopleId: string,
  signature: string,
  campaignId: string
) => {
  return doCallout({
    method: "GET",
    url: `${process.env.REACT_APP_FROZEN_II_ENDPOINT}/member/${peopleId}/postpone?signature=${signature}&campaignId=${campaignId}`,
  });
};

const doCallout = async (
  options: AxiosRequestConfig
) => {
  const response = await axios(options).catch((error: AxiosError) => {
    return error.response;
  });
  if (
    response &&
    response.status &&
    (response.status === 200 || response.status === 201 || response.status === 401 || response.status === 432 || response.status === 433 || response.status === 434)
  ) {
    if(response.status === 200 && response.data){
      const campaign = verify(campaignSpec, response.data).value();
      campaign.labels = verify(labels, JSON.parse(campaign.Description)).value();
      return campaign;
    }
    return response.status;
  }
  return 500;
};
