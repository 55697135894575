import React, { useState } from 'react';

import { setCompensation } from '../api/compensation';

export default ({ labels, peopleId, signature, campaignId, setError, setPage, setWeeks }) => {
  const [freezeChoice, setFreezeChoice] = useState();
  const [checkbox, setCheckbox] = useState(false);
  const [doubleClick, setDoubleClick] = useState(false);

  const submitFunction = async () => { 

    setDoubleClick(true);

    if (!freezeChoice) { return setError(true); }
    const status = await setCompensation(peopleId, signature, freezeChoice.choice, campaignId);
    console.log(status);
    if (status === 500) {
      setError(true);
    } else if (status === 200 || status === 201) {
      setPage('thank');
    } else if (status === 401) {
      // not authorized, signature is invalid
      setError(true);
    } else if (status === 432) {
      // show had visit page
      setPage('hadVisit');
    } else if (status === 433) {
      // show had visit page
      setPage('alreadyFrozen');
    } else {
      setError(true);
    }
  }

  const setFreeze = (weeks) => {
    setFreezeChoice(weeks);
    setWeeks(weeks.weeks);
  };

  const checkboxClickedHandler = () => {
    setCheckbox(!checkbox);
  }

  const goToBasicFit = () => {
    const link = 'https://www.basic-fit.com';
    console.log('::: link ::', link);
    window.location.replace(link);
  };
  
  const freezeOptions = [
    { weeks: 2, choice: 'FrozenII-2weeks' },
    // { weeks: 4, choice: 'FrozenII-4weeks' },
    // { weeks: 6, choice: 'FrozenII-6weeks' }
  ];

  return (
    <>
      <div className="header">
        <p>{labels[1]}</p>
        <p>{labels[2]}</p>
      </div>

      <div className="inner-box">
        <div className="text-field">
        <p>{labels['description1']}</p>
          {/* <p>{labels[3]}</p>
          <br />
          <p>{labels[3.1]}</p>
          <br />
          <ul className="bullet-list">
            {labels[3.2].map((label, i) => {
              return (
                <li key={i}>
                  <div>{label}</div>
                </li>
              );
            })}
          </ul> */}
        </div>

        <div className="sub-header">{labels[18]}</div>

        <div className="text-field">
          {/* <p>{labels[19]}</p>
          <br /> */}

          {freezeOptions.map((option) => {
            return (
              <div
                key={`weeks-${option.weeks}`}
                onClick={() => setFreeze(option)}
                className="button-box"
              >
                <div className="check">
                  {freezeChoice && freezeChoice.choice === option.choice ? (
                    <img src="img/icons/radio-on.svg" alt="radiobutton on" />
                  ) : (
                    <img src="img/icons/radio-off.svg" alt="radiobutton off" />
                  )}
                </div>
                <p>{labels['choice']}</p>
              </div>
            );
          })}

          {freezeChoice && (
            <>
              <div>
                <hr />
              </div>

              <div className="sub-header">{labels[21]}</div>
              <p>{labels['description2']}</p>
              {/* <div className="bullet-list">
                <ul>
                  {labels[22].map((label, i) => {
                    return (
                      <li key={`${i}-x`}>
                        <div>
                          {label.replace(/%%weeks%%/g, freezeChoice.weeks)}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div> */}

              <div className="button-box" onClick={checkboxClickedHandler}>
                <div className="check">
                  {checkbox ? (
                    <img
                      src="img/icons/Checkbox-Active.svg"
                      alt="checkbox on"
                    />
                  ) : (
                    <img src="img/icons/Checkbox.svg" alt="radiobutton off" />
                  )}
                </div>
                <p>{labels[23]}</p>
              </div>
            </>
          )}
        </div>

        <button
          onClick={submitFunction}
          disabled={!checkbox || doubleClick}
          className={`submit-button ${checkbox ? 'active' : 'unactive'}`}
        >
          {labels[4]}
        </button>

        <div onClick={goToBasicFit} className="cancel cursor-pointer">
          {labels[13]}
        </div>
      </div>
    </>
  );
}
