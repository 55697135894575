import React, { useState } from 'react';
import copy from '../data/copy';

import useComponentVisible from './useComponentVisible';

export default ({ language, setLanguage, langs, campaignLabels, setLabels }) => {
  const [langMenuOpen, setLangMenuOpen] = useState(false);
  const { ref, isComponentVisible } = useComponentVisible(true);

  const setLang = (lang) => {
    setLanguage(lang);
    setLangMenuOpen(false);
    let newLabels = copy[lang];
    if(campaignLabels){
      campaignLabels.map(vals => {
        if(vals.language === lang){
          newLabels = {...newLabels, ...vals};
        }
        return;
      });
    }
    setLabels(newLabels);
  }

  return (
    <>
      <div
        onClick={() => setLangMenuOpen(!langMenuOpen)}
        className="language-menu-top"
      >
        <div className={'language-short ' + (langMenuOpen ? 'open' : '')}>
          {language || 'NL'}
        </div>

        <img
          className={
            'lang-arrow ' + (langMenuOpen ? 'rotate-up' : 'rotate-down')
          }
          src={langMenuOpen ? '/img/icons/arrow.svg' : '/img/icons/arrow-grey.svg'}
          alt="arrow"
        />
      </div>

      {langMenuOpen && (
        <div ref={ref}>
          {isComponentVisible ? (
            <>
              <div className="lang-section-back"></div>
              <div className="lang-menu">
                {langs.map((lan, index) => {
                  return (
                    <div key={index} className="lang-row">
                      <div
                        onClick={() => setLang(lan)}
                        className={language === lan ? 'orange' : ''}
                      >
                        {lan}
                      </div>
                      {langs.length > index + 1 && (
                        <div className="lang-divider"></div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>{setLangMenuOpen(false)}</>
          )}
        </div>
      )}
    </>
  );
};
