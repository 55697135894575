import React from 'react';
import Loader from 'react-loader-spinner';

export default () => {
  return (
    <div className="overlay">
      <Loader className="vertically-centered loader"
        type="Puff"
        color="#7322C9"
        height={100}
        width={100}
        timeout={500000}
      />
    </div>
  );
}
