export const choiceReducer = (state = { choice: false }, action) => {
  switch (action.type) {
    case "CHOICE":
      return {
        ...state,
        choice: action.payload
      };
    default:
      return state;
  }
}