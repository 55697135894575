import React from 'react';
import Go from "./go-to-bf"

export default ({ labels, onClickFunction }) => {
  return (
    <>
      <div className="header">{labels[8]}</div>

      <div className="inner-box">
        <div className="text-field">{labels[9]}</div>

        <Go text={labels[7]} submitFunction={onClickFunction}></Go>
      </div>
    </>
  );
}
