export const pageReducer = (state = { page: "home" }, action) => {
  switch (action.type) {
    case "PAGE":
      return {
        ...state,
        page: action.payload
      };
    default:
      return state;
  }
}