/* eslint-disable @typescript-eslint/camelcase */
import { Type, adjust, optional } from "specified";

export const labelsInLanguage = adjust(
  Type.object({
    language: Type.literal({ nl: 1, es: 1, fr: 1, en: 1 }),
    description1: Type.string,
    description2: Type.string,
    choice: Type.string,
    campaign_faq_link: optional(Type.string),
    thanks_link_1_url: optional(Type.string),
    thanks_link_2_url: optional(Type.string),
    thanks_link_3_url: optional(Type.string),
  }),
  { strict: false }
);

export const labels = Type.array(labelsInLanguage);

export const campaign = adjust(
  Type.object({
    Id: Type.string,
    StartDate: Type.string,
    EndDate: Type.string,
    Gym_Visits_End_Date__c: Type.string,
    FrozenII_reactivation_date__c: Type.string,
    Description: Type.string,
    labels: optional(labels)
  }),
  {
    strict: false
  }
);