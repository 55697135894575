import React from 'react';

export default ({ text, submitFunction }) => {
  return (
    <div onClick={submitFunction} className="go-to-bf-button">
      <div className="text-btn-left">{text}</div>
      <div>
        <img className="img-btn-right" src="/img/icons/chevron-orange.svg" alt="radiobutton on" />
      </div>
    </div>
  );
}
