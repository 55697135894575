import React, { useState, useEffect } from 'react';

import Home from './components/home';
import Thank from './components/thank-you';
import Error from './components/error';
import LanguageSection from './components/language-section';
import LoaderOverlay from './components/loader-overlay';
import HadVisit from './components/had-visit';
import Disabled from './components/disabled';

import copy from './data/copy';
import './App.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import queryString from 'query-string';

import { checkCompensation } from './api/compensation';
import AlreadyFrozen from './components/already-frozen';

import lcidMap from './data/lang-country';
import WaveNotActive from './components/waveNotActive';

export default () => {
  const params = queryString.parse(window.location.search);
  const [loader, setLoader] = useState(true);
  const [success] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState();
  const [weeks, setWeeks] = useState();
  const [langs, setLangs] = useState(['nl','fr','en', 'es']);

  const [language, setLanguage] = useState(lcidMap(params.lcid));
  const [labels, setLabels] = useState(copy[language]);
  const [campaign, setCampaign] = useState();

  useEffect(() => {
    (async () => {
      if (params.peopleId && params.signature && params.campaignId) {
        const status = await checkCompensation(
          params.peopleId,
          params.signature,
          params.campaignId
        );
        // const status = 'disabled';
        console.log(status);
        if(status === 'disabled'){
          setPage('disabled');
        }
        else if (status === 500) {
          setError(true);
        } else if (status.Id === params.campaignId) {
          setCampaign(status);
          setLangs(status.labels && status.labels.map(val => {return val.language}));
          let newLabels = {...labels};
          status.labels.map(vals => {
            if(vals.language === language){
              newLabels = {...newLabels, ...vals};
            }
            return;
          });
          setLabels(newLabels);
          setPage('home');
        } else if (status === 401) {
          // not authorized, signature is invalid
          setError(true);
        } else if (status === 432) {
          // show had visit page
          setPage('hadVisit');
        } else if (status === 433) {
          // show had visit page
          setPage('alreadyFrozen');
        }  else if (status === 434) {
          setPage('waveNotActive');
        }else {
          setError(true);
        }
      } else {
        // show error - no parameters in url
        setError(true);
      }
      setLoader(false);
    })();
  }, []);

  const goToBasicFit = () => {
    const link = 'https://www.basic-fit.com';
    console.log('::: link ::', link);
    window.location.replace(link);
  };

  const goToFaq = () => {
    const link = labels.campaign_faq_link || labels.faq;
    console.log('::: link ::', link);
    window.location.replace(link);
  };

  return (
    <>
      {/* {loaderOverlay && <LoaderOverlay />} */}
      {langs && 
        <div className="logo-section">
          <div className="logo-box">
            <img src="/img/logo.svg" alt="Basic-Fit Logo" />
          </div>
          <div className="language-section">
            <LanguageSection language={language} setLanguage={setLanguage} langs={langs} campaignLabels={campaign && campaign.labels} setLabels={setLabels} />
          </div>
        </div>
        }
        <div>
        {!loader ? (
          <>
            <div className="contents">
              <div className="contents-back">
                <div className="tilted-div"></div>
              </div>

              {!error && page === 'disabled' && (
                <Disabled labels={labels} onClickFunction={goToFaq} />
              )}

              {!error && page === 'home' && (
                <Home
                  labels={labels}
                  peopleId={params.peopleId}
                  signature={params.signature}
                  campaignId={params.campaignId}
                  setPage={setPage}
                  setError={setError}
                  setWeeks={setWeeks}
                />
              )}

              {!error && page === 'thank' && (
                <Thank
                  labels={labels}
                  onClickFunction={goToFaq}
                  weeks={weeks}
                />
              )}

              {!error && page === 'alreadyFrozen' && (
                <AlreadyFrozen labels={labels} onClickFunction={goToFaq} />
              )}

              {!error && page === 'hadVisit' && (
                <HadVisit labels={labels} onClickFunction={goToFaq} />
              )}

              {!error && page === 'waveNotActive' && (
                <WaveNotActive labels={labels} onClickFunction={goToFaq} />
              )}

              {error && (
                <Error labels={labels} onClickFunction={goToBasicFit} />
              )}
            </div>
          </>
        ) : (
          <LoaderOverlay success={success} labels={labels} />
        )}
      </div>
    </>
  );
}; 
