export const labelsReducer = (state = { labels: {} }, action) => {
  switch (action.type) {
    case "LABELS":
      return {
        ...state,
        labels: action.payload
      };
    default:
      return state;
  }
}