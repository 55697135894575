import React from 'react';
import Go from "./go-to-bf";

export default ({ labels, onClickFunction, weeks }) => {
  const openWindow = (url) => {window.open(url,'_blank');};
  return (
    <>
      <div className="header">{labels[25]}</div>

      <div className="inner-box">
        <div className="text-field">{labels[6].replace(/%%weeks%%/g, weeks)}</div>

        <Go text={labels['thanks_link_1_text']} submitFunction={()=>{openWindow(labels['thanks_link_1_url'])}}></Go>
        <Go text={labels['thanks_link_2_text']} submitFunction={()=>{openWindow(labels['thanks_link_2_url'])}}></Go>
        <Go text={labels['thanks_link_3_text']} submitFunction={()=>{openWindow(labels['thanks_link_3_url'])}}></Go>
      </div>
    </>
  );
}
