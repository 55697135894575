const lcidMap: Record<string, "nl" | "fr" | "es" | "en" > = {
  "1043": "nl",
  "1036": "fr",
  "1034": "es",
  "2060": "fr",
  "2067": "nl",
  "5132": "fr",
  "3082": "es",
  "2057": "en",
  "1033": "en"
};

export default (lcid: string): "nl" | "en" | "fr" | "es" => {
  return lcidMap[lcid] || "nl";
};
