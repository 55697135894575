export const languageReducer = (state = { language: false }, action) => {
  switch (action.type) {
    case "LANGUAGE":
      return {
        ...state,
        language: action.payload
      };
    default:
      return state;
  }
}