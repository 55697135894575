// 14, 15 and 17 need check
export default {
  "en": {
    "1": "The Live",
    "2": "Grouplessons Extra",
    "3": "In recent weeks we have worked very hard to be able to open as safely as possible, according to all guidelines from the government and local health entities. We let fewer people in at the same time, we clean extra often and it is quite possible in the club to keep away from each other and clean the devices before and after use.",
    "3.1": "One will be eager to come back to the club immediately, the other would rather wait a while. You have to do what feels right to you and we respect everyone's choice. If you are not yet ready for a club visit, we offer you the option to stay frozen for an extra 2 weeks from opening date. You do not have to pay for these weeks. There are a few conditions attached to it:",
    "3.2": [
      "You need to make your choice before June 22nd (for Spain) or June 19th (for France) at midnight",
      "If you have already visited the club, you can no longer choose the freeze option.",
      "If you decide to stay frozen, you will not be able to make a reservation (and therefore not exercise in the club).",
      "During the freeze you do not pay, but (if applicable) your contract end date would be postponed with the freeze period.",
      "After your frozen weeks have expired, you can book and exercise again! You will then also pay for your membership again."
    ],
    "4": "Confirm",
    "5": "Thank you!",
    "6": "The Live Grouplessons Extra will be discontinued for you. Too bad, but fortunately as a Basic-Fit member you can still use a lot of our other great tools!",
    "7": "Go to Basic-Fit website",
    "8": "Error!",
    "9": "Something went wrong while submitting your choice. We are sorry! Please try again later.",
    "10": "Go to Basic-Fit website",
    "11": "Loading",
    "12": "Submitted!",
    "13": "Cancel",
    "14": "Expired!",
    "15": "No longer valid! You have already submitted your compensation choice. You can only do this once. Thanks!",
    "16": "Invalid!",
    "17": "Something went wrong. You did not reach this page via the personalized link in the email.",
    "18": "Do you still want to stop the Live Grouplessons Extra?",
    "19": "Not ready to come for a workout yet? You can leave your membership frozen for 2 extra weeks from the date of opening of the clubs. Choose the option below.",
    "20": "weeks from the reopening of your club",
    "21": "What happens next?",
    "22": [
      "Your membership will be frozen for %%weeks%% weeks from the opening date of your club",
      "You will not be able to access the club",
      "You obviously do not pay anything for your membership in these %%weeks%% weeks, the end date of your contract is shifted (if applicable)",
      "After the two weeks, your membership will be automatically reactivated and you can make a reservation to come train in your Basic-Fit club. You will also pay again from that moment on."
    ],
    "23": "I confirm that I want to stop the Live Grouplessons Extra",
    "24": "Basic-Fit Website",
    "25": "Your selection is confirmed.",
    "26": "The Live Grouplessons Extra will be dicontinued for you. We will process the correction in your account so that you will no longer pay for the live group lessons. Depending on your membership and payment term, you will receive a correction or refund. Its possible that the correction will have just crossed with the direct debit but we will automatically adjust it for you so you don't pay too much. You don't have to do anything for this to happen.",
    "27": "Re-Freeze isn't Possible",
    "28": "Oh oh! We see you've already had a club visit. It is therefore not possible to freeze your membership.",
    "faq": "https://www.basic-fit.com/en-be/why-basic-fit",
    "disabled_text": "Sorry, too late",
    "disabled_title": "Re-Freeze isn't Possible",
    "waveNotActiveTitle":"Sorry, this link doesn't work anymore. ",
    "waveNotActiveText":"It is no longer possible to proceed with this request.",
    "thanks_link_1_text": "Train at home with the Basic-Fit app",
    "thanks_link_1_url": "https://www.basic-fit.com/en-be/home-outdoor-training",
    "thanks_link_2_text": "GXR On-Demand",
    "thanks_link_2_url": "https://www.basic-fit.com/en-be/groupclasses/gxrondemand",
    "thanks_link_3_text": "Audio Workouts & Podcasts",
    "thanks_link_3_url": "https://www.basic-fit.com/en-be/audio-training",
    "already_chosen": "You have already informed us that you want to stop the Live Group Lessons Extra. ",
  },
  "nl": {
    "1": "De live",
    "2": "groepslessen extra",
    "3": "Yessss! Sinds 8 juni zijn onze clubs in België weer open! We volgen natuurlijk alle richtlijnen van de overheid en houden ons aan de maatregelen. Dit doen we om alle medewerkers en leden zo veilig mogelijk te laten sporten in de club.",
    "3.1": "Aangezien in Antwerpen op vraag van de gouverneur heel wat fitnessclubs preventief de deuren hebben moeten sluiten, willen we jou de mogelijkheid geven om je lidmaatschap (opnieuw) te bevriezen. Zit jij in deze regio en kan je nu niet komen sporten? Bevries dan nu jouw lidmaatschap tot 26 augustus.",
    "3.2": [
      "Tijdens de freeze periode betaal je niet, maar wordt (indien van toepassing) je contract einddatum met de freeze periode opgeschoven.",
      "Na deze freeze periode, kan je weer reserveren en sporten! Uiteraard ga je na de freeze terug betalen voor je lidmaatschap",
      "Opgelet: als je nog komen sporten bent, kan je geen gebruik maken van deze ‘freeze’ functionaliteit",
    ],
    "4": "Bevestigen",
    "5": "Dank je!",
    "6": "De Live Groepslessen Extra wordt voor je stopgezet. Jammer, maar gelukkig kan je als Basic-Fit lid nog wel van heel veel andere mooie tools gebruikmaken!",
    "7": "Door naar Basic-Fit website",
    "8": "Fout!",
    "9": "Er is iets misgegaan bij het insturen van je keuze. Het spijt ons! Probeer het later opnieuw.",
    "10": "Door naar basic-fit website",
    "11": "Laden",
    "12": "Verzonden!",
    "13": "Annuleren",
    "14": "Verlopen!",
    "15": "Niet meer geldig! Je hebt je keuze voor compensatie al aan ons doorgegeven. Dit kun je maar een keer doen. Bedankt!",
    "16": "Ongeldig!",
    "17": "Er is iets misgegaan. Je bent niet op deze pagina gekomen via de gepersonalizeerde link in de email.",
    "18": "Wil je toch de Live Groepslessen Extra stoppen?",
    "19": "Helaas is deze optie niet meer beschikbaar.",
    "20": "FREEZE",
    "21": "Hoe werkt het verder?",
    "22": [
      "Je lidmaatschap is bevroren",
      "Je kan tijdens die periode niet langer reserveren en sporten in onze Basic-Fit club",
      "Je betaalt uiteraard niets voor je lidmaatschap tijdens deze periode. De einddatum van je contract wordt verschoven (indien van toepassing)"
    ],
    "23": "Ik bevestig dat ik de Live Groepslessen Extra wil stoppen",
    "24": "Basic-Fit Website",
    "25": "Je keuze is bevestigd",
    "26": "De Live Groepslessen Extra wordt voor je stopgezet. We verwerken een correctie zodat je niet langer betaalt voor de live groepslessen. Afhankelijk van je lidmaatschap en je betaaltermijn ontvang je een correctie of een restitutie. De correctie kan mogelijk de automatische incasso net gekruist hebben maar wij passen het automatisch voor je aan zodat je niet te veel betaalt. Hier hoef jij niks voor te doen. ",
    "27": "Re-Freeze Niet Mogelijk",
    "28": "Oh oh! We zien dat je al een clubbezoek hebt gehad. Het is daarom niet mogelijk om je lidmaatschap te freezen.",
    "faq": "https://www.basic-fit.com/nl-be/waarombasic-fit",
    "disabled_text": `Yessss! Woensdag 5 augustus kondigde de gouverneur van Antwerpen aan dat alle clubs in de regio terug open mogen. Niet nodig dus om je abonnement te bevriezen want je kan vanaf morgen terug in jouw vertrouwde club komen sporten. Check meteen jouw Basic-Fit app of surf naar My Basic-Fit en reserveer jouw trainingsmoment in Gymtime.`,
    "disabled_title": "Goed nieuws",
    "waveNotActiveTitle":"Sorry, deze link werkt niet meer.",
    "waveNotActiveText":"Het is niet meer mogelijk om verder te gaan met dit verzoek.",
    "thanks_link_1_text": "Train thuis met de Basic-Fit app",
    "thanks_link_1_url": "https://www.basic-fit.com/nl-be/thuis-buiten-trainen",
    "thanks_link_2_text": "GXR On-Demand",
    "thanks_link_2_url": "https://www.basic-fit.com/nl-be/groepslessen/gxrondemand",
    "thanks_link_3_text": "Audio Coach trainingen & Podcasts",
    "thanks_link_3_url": "https://www.basic-fit.com/nl-be/audio-training",
    "already_chosen": "Je hebt al doorgegeven dat je de Live Groepslessen Extra wil stoppen",
  },
  "fr": {
    "1": "Cours collectifs",
    "2": "Live en option",
    "3": "Yessss ! Depuis le 8 juin, nos clubs en Belgique sont à nouveau ouverts ! Bien sûr, nous suivons toutes les directives du gouvernement et nous nous en tenons aux mesures. Nous le faisons afin de permettre à tous les employé(e)s et membres de faire du sport dans le club de la manière la plus sûre possible.",
    "3.1": "Comme de nombreuses salles de fitness d'Anvers ont dû fermer leurs portes de manière préventive à la demande du gouverneur, nous aimerions vous donner la possibilité de (re)geler votre abonnement. Vous êtes dans cette région et vous ne pouvez pas venir faire du sport ? Alors bloquez votre abonnement avant le 26/08 et ce jusqu'au 26 août.",
    "3.2": [
      "Pendant la période de gel, vous ne paierez pas, mais (le cas échéant) la date de fin de votre contrat sera reportée avec la période de gel",
	    "Après la période de gel, vous pouvez à nouveau réserver et faire du sport ! Bien entendu, vous devrez rembourser votre abonnement après la période de gel",
      `Attention : si vous venez encore faire du sport après le 29/09, vous ne pouvez pas utiliser cette fonction de "gel"`,
    ],
    "4": "Confirmer",
    "5": "Merci !",
    "6": "L'option LGX sera supprimée pour vous. C'est dommage, mais heureusement, en tant que membre Basic-Fit, vous pouvez encore utiliser beaucoup d'autres moyens formidables.",
    "7": "Revenir au site internet Basic-Fit",
    "8": "Erreur!",
    "9": "Quelque chose ne s'est pas bien passé lorsque vous avez fait votre choix. Veuillez réessayer plus tard.",
    "10": "Revenir au site internet Basic-Fit",
    "11": "Chargement",
    "12": "Soumis !",
    "13": "Annuler",
    "14": "Expiré !",
    "15": "Ce n'est plus valide ! Vous avez déjà soumis votre choix de compensation. Vous ne pouvez le faire qu'une seule fois. Merci.",
    "16": "Invalide !",
    "17": "Quelque chose n'a pas fonctionné. Vous n'avez pas atteint cette page via le lien personnalisé dans l'email.",
    "18": "Vous voulez quand même arrêter l'option des cours collectifs Live GXR?",
    "19": "Vous n'êtes pas encore prêt(e)s à revenir vous entraîner ? Vous pouvez laisser votre abonnement 'en gel' pendant 2 semaines supplémentaires à partir de la réouverture de votre club. Choisissez le nombre de semaines ci-dessous.",
    "20": "Votre abonnement est gelé",
    "21": "Comment cela fonctionne-t-il?",
    "22": [
      "Votre abonnement est gelé",
      "Jusqu'à cette période, vous ne pouvez plus faire de réservations et faire du sport dans nos clubs Basic-Fit.",
      "Bien entendu, vous ne payez rien pendant cette période. La date de fin de votre contrat sera reportée (le cas échéant)."
    ],
    "23": "Je confirme que je veux arrêter l'option des cours collectifs Live",
    "24": "Basic-Fit Website",
    "25": "Votre choix est confirmé",
    "26": "L'option LGX sera supprimée pour vous. Nous procédons à une correction afin que vous ne payiez plus les cours collectifs Live. En fonction de votre abonnement et du délai de paiement, vous recevrez une correction ou un remboursement. Il se peut que la correction vienne de franchir la limite de la domiciliation, mais nous l'ajusterons automatiquement pour vous afin que vous ne payiez pas trop. Vous n'avez rien à faire pour cela. ",
    "27": "Le gel de votre abonnement est impossible",
    "28": "Oh ! Nous voyons que vous êtes déjà venu(e)s vous entraîner dans un club Basic-Fit durant ces 15 jours de fermeture imposés par l’état. Il n'est donc pas possible de geler votre abonnement.",
    "faq": "https://www.basic-fit.com/fr-be/pourquoi-basic-fit",
    "disabled_text": `Yessss ! Mercredi 5 août, le gouverneur d'Anvers a annoncé que tous les clubs de la région pourraient rouvrir. Pas besoin de geler votre abonnement, car vous pouvez revenir demain dans votre club en toute confiance. Vérifiez immédiatement votre application Basic-Fit ou surfez sur Mon Basic-Fit et réservez votre entraînement sous l’onglet « Réservation Entraînements ».`,
    "disabled_title": "Bonne nouvelle",
    "waveNotActiveTitle":"Désolé, ce lien ne fonctionne plus.",
    "waveNotActiveText":"Il n'est plus possible de donner suite à cette demande.",
    "thanks_link_1_text": "S'entraîner à la maison avec l'application Basic-Fit",
    "thanks_link_1_url": "https://www.basic-fit.com/fr-be/entrainement-domicile-plein-air",
    "thanks_link_2_text": "GXR à la demande",
    "thanks_link_2_url": "https://www.basic-fit.com/fr-be/cours-collectifs/gxrondemand",
    "thanks_link_3_text": "Entraînements audio et podcasts",
    "thanks_link_3_url": "https://www.basic-fit.com/fr-be/audio-training",
    "already_chosen": "Vous nous avez déjà fait savoir que vous vouliez enlever l'option LGX",
  },
  "es": {
    "1": "Clases colectivas",
    "2": "presenciales opcionales",
    "3": "En las últimas semanas hemos trabajado duro para poder abrir de la manera más segura posible de acuerdo a todas las pautas establecidas por el gobierno. Permitimos menos accesoss al gimnasio en un mismo periodo de tiempo, hemos reforzado la limpieza y hemos adaptado el gimnasio para entrenar con distancia de seguridad y para poder poder limpiar todo el equipamiento antes y después de su uso.",
    "3.1": "Algunos estarán deseando volver al gimnasio lo antes posible, pero otros preferirán esperar un poco más. Debes hacer lo que te parezca correcto y respetamos la decisión de todos vosotros. Si aún no estás preparado para regresar al gimnasio, te ofrecemos la opción de permitir que tu inscripción siga congelada por 2 semanas más. No tendrás que abonar nada por estas semanas pero hay algunas condiciones asociadas a esto:",
    "3.2": [
      "Tienes que elegir tu opción antes de las 00:00 del 22 de junio",
	  "Si ya has accedido al gimnasio, no podrás elegir la opción de congelación.",
      "Si congelas tu inscripción (nuevamente) no podrás hacer una reserva (y por lo tanto no podrás entrenar en el gimnasio).",
      "Durante el periodo de congelación no abonarás nada pero (si corresponde) la fecha de finalización de tu contrato se pospondrá por el mismo periodo que el tiempo de congelación seleccionado.",
      "¡Después de que tus semanas de congelación hayan finalizado puedes reservar y entrenar nuevamente! En ese momento comenzarás a abonar tu inscripción de nuevo."
    ],
    "4": "Confirmar",
    "5": "¡Gracias!",
    "6": "Hemos cancelado tu extra de clases colectivas presenciales. Es una lástima que ya no lo quieras... Aunque puedes seguir disfrutando del resto de los servicios de Basic-Fit.",
    "7": "Ir a la página web de Basic-Fit",
    "8": "Error!",
    "9": "Algo salió mal al seleccionar tu opción. ¡Lo sentimos! Por favor, inténtelo de nuevo más tarde.",
    "10": "Ir a la página web de Basic-Fit",
    "11": "Cargando",
    "12": "¡Enviado!!",
    "13": "Salir",
    "14": "¡Caducada!",
    "15": "¡Ya no es valido! Ya has enviado tu elección de compensación. Sólo puedes hacer esto una vez. ¡Gracias!",
    "16": "¡Inválido!",
    "17": "Algo salió mal. Yo has llegado a esta página a través del link personal de tu email.",
    "18": "¿Quieres cancelar el extra de clases colectivas presenciales GXR?",
    "19": "¿Todavía no estás listo para entrenar? Puedes mantener tu cuota congelada durante 2 semanas desde la fecha de apertura.",
    "20": "semanas desde la fecha de apertura",
    "21": "¿Qué sucederá si lo hago?",
    "22": [
      "Tu inscripción se congelará durante %%weeks%% semanas desde la fecha de apertura",
      "No podrás acceder al gimnasio",
      "Obviamente no pagarás nada de tu inscripción en estas %%weeks%% semanas y la fecha de finalización de tu contrato queda desplazada (si corresponde)",
      "Después de %%weeks%% semanas, tu inscripción se reactivará automaticamente y podrás hacer una reserva para tu entrenamiento. También pagarás de nuevo desde ese momento."
    ],
    "23": "Confirmo que quiero cancelar el extra de clases colectivas presenciales",
    "24": "Web Basic-Fit",
    "25": "Tu petición se ha confirmado.",
    "26": "A partir de ahora ya no contarás con el extra de clases colectivas presenciales en tu tarifa. Corregiremos este cambio en tu cuota para que no se te pase ningún recibo. Sin embargo, dependiendo de tu tarifa y del plazo de pago en el que te encuentres al realizar este cambio, puede que recibas una compensación o un reembolso. Si se da el caso en el que esta corrección se cruza con la domiciliación bancaria, lo rectificaremos automáticamente para que no pagues de más. No necesitas realizar ninguna otra acción.",
    "27": "Volver a congelar tu inscripción no es posible",
    "28": "Oh oh! Vemos que ya has tenido un acceso al gimnasio. Por lo tanto no es posible congelar tu inscripción.",
    "faq": "https://www.basic-fit.com/es-es/para-quien-es-basic-fit",
    "disabled_text": ` 
      En las últimas semanas hemos trabajado duro para poder abrir de la manera más segura posible de acuerdo a todas las pautas establecidas por el gobierno. Permitimos menos accesoss al gimnasio en un mismo periodo de tiempo, hemos reforzado la limpieza y hemos adaptado el gimnasio para entrenar con distancia de seguridad y para poder poder limpiar todo el equipamiento antes y después de su uso.
      Algunos estarán deseando volver al gimnasio lo antes posible, pero otros preferirán esperar un poco más. Debes hacer lo que te parezca correcto y respetamos la decisión de todos vosotros. Si aún no estás preparado para regresar al gimnasio, te ofrecemos la opción de permitir que tu inscripción siga congelada por 2 semanas más. No tendrás que abonar nada por estas semanas pero hay algunas condiciones asociadas a esto:
      Tienes que elegir tu opción antes de las 00:00 del 22 de junio.
      Si ya has accedido al gimnasio, no podrás elegir la opción de congelación.
      Si congelas tu inscripción (nuevamente) no podrás hacer una reserva (y por lo tanto no podrás entrenar en el gimnasio).
      Durante el periodo de congelación no abonarás nada pero (si corresponde) la fecha de finalización de tu contrato se pospondrá por el mismo periodo que el tiempo de congelación seleccionado.
      ¡Después de que tus semanas de congelación hayan finalizado puedes reservar y entrenar nuevamente! En ese momento comenzarás a abonar tu inscripción de nuevo.
      Si quieres elegir esta opción, envía un email a servicioalcliente@basic-fit.com, ¡lo gestionamos por ti!`,
    "disabled_title": "Vuelve a congelar tu inscripción",
    "waveNotActiveTitle":"Lo siento, este link ha dejado de funcionar. ",
    "waveNotActiveText":"No es posible proceder a esta solicitud.",
    "thanks_link_1_text": "Entrena en casa con la app de Basic-Fit",
    "thanks_link_1_url": "https://www.basic-fit.com/es-es/entrenamiento-casa-aire-libre",
    "thanks_link_2_text": "GXR On-Demand",
    "thanks_link_2_url": "https://www.basic-fit.com/es-es/clases-colectivas/gxrondemand",
    "thanks_link_3_text": "Entrenamientos de audio & podcasts",
    "thanks_link_3_url": "https://www.basic-fit.com/es-es/audio-training",
    "already_chosen": "Nos acabas de notificar que deseas cancelar tu extra de clases colectivas presenciales.",
  }
};