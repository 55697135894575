import { combineReducers } from "redux";
import { labelsReducer } from "./labelsReducer";
import { compensationReducer } from "./compensationReducer";
import { pageReducer } from "./pageReducer";
import { languageReducer } from "./languageReducer";
import { choiceReducer } from "./choiceReducer";

export default combineReducers({
  labelsReducer,
  compensationReducer,
  pageReducer,
  languageReducer,
  choiceReducer
});